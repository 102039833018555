<template>
  <div class="d-md-none row fixed-top phone-top">
    <div class="col">
      <router-link to="/" class="nav_logo">
        <img src="/assets/img/logo.png" alt="" />
        <span class="nav_logo-name">Prodyflow</span>
      </router-link>
    </div>
    <div class="col text-end">
      <button
        class="btn btn-icon"
        v-on:click="this.$store.commit('toggleSearchMenu')"
      >
        <BaseIcon name="search" />
      </button>
      <button
        class="btn btn-icon"
        v-on:click="this.$store.commit('toggleRightMenu')"
      >
        <BaseIcon name="app-menu" />
      </button>
      <button class="btn btn-icon" v-on:click="this.show = !this.show">
        <BaseIcon name="menu" />
      </button>
    </div>
  </div>
  <div
    class="l-navbar"
    :class="{ show: show }"
    v-on:mouseenter="changeShow(true)"
    v-on:mouseleave="changeShow(false)"
    id="nav-bar"
  >
    <nav class="nav">
      <div>
        <a href="#" class="nav_logo d-none d-md-flex">
          <img src="/assets/img/logo.png" alt="" />
          <span class="nav_logo-name">Prodyflow</span>
        </a>
        <div class="nav_list custom-input">
          <select
            class="form-select"
            :aria-label="$t(`chart1.defaultSelectExample`)"
          >
            <option selected>{{ $t("leftSidebar.online") }}</option>
            <option value="1">{{ $t("leftSidebar.offline") }}</option>
          </select>
          <MenuBar
            :key="key"
            v-bind:menu-items="filteredMenus"
            v-on:selected="menuSelected"
          />

          <button class="collapse-button" v-on:click="showNavbar()">
            <div
              class="button-box d-none d-md-block"
              id="header-toggle"
              v-bind:class="{ opened: show }"
            >
              <img
                class="nav-open"
                v-if="!basicShow"
                src="/assets/icons/sidebar-left/menu-open.png"
                alt=""
              />
              <img
                class="nav-open"
                v-if="basicShow"
                src="/assets/icons/sidebar-left/menu-close.png"
                alt=""
              />
            </div>
          </button>
        </div>
      </div>
      <router-link to="/settings" class="nav_link settings">
        <div class="rounded-box-border">
          <BaseIcon name="settings" />
          <span class="nav_name">{{ $t("menu.settings") }}</span>
        </div>
      </router-link>
    </nav>
  </div>
</template>

<script>
import MenuBar from "./sidebar/MenuBar";
import BaseIcon from "./icons/BaseIcon";
import { useStore } from "vuex";
export default {
  name: "LeftSidebar",
  components: { BaseIcon, MenuBar },
  props: {
    activeMenu: String,
  },
  data() {
    const store = useStore();

    return {
      store: store,
      show: false,
      basicShow: false,
      appMenuIds: [],
      menus: [
        {
          name: this.$t("menu.home"),
          image: "dashboard",
          url: "/",
          path: ["home"],
        },
        {
          name: this.$t("menu.outgoing"),
          image: "outgoing",
          path: [
            "orders",
            "warehouses::outgoing::problems",
            "warehouses::outgoing",
          ],
          url: null,
          subMenus: [
            {
              name: this.$t("menu.sales"),
              image: "bag",
              url: "/sales",
              path: ["orders"],
            },
            {
              name: this.$t("menu.outgoing"),
              image: "outgoing-building",
              url: "/outgoing",
              path: ["warehouses::outgoing"],
            },
          ],
        },
        {
          name: this.$t("menu.incoming"),
          image: "incoming",
          url: null,
          path: ["warehouses::income"],
          subMenus: [
            {
              name: this.$t("menu.manual-income"),
              image: "manual-incoming-building",
              url: "/incoming/manual-incoming",
              path: ["warehouses::income::manual"],
            },
            {
              name: this.$t("menu.income"),
              image: "incoming-building",
              url: "/incoming",
              path: ["warehouses::income"],
            },
          ],
        },
        {
          name: this.$t("menu.stockControl"),
          image: "warning",
          url: "/outgoing/problems",
          path: ["warehouses::outgoing::problems"],
          subMenus: [
            {
              name: this.$t("menu.problems"),
              image: "warning",
              url: "/outgoing/problems",
              path: ["warehouses::outgoing::problems"],
            },
            {
              name: this.$t("menu.inventoryAudit"),
              image: "mouse-circle",
              url: "/outgoing/inventory-audit",
              path: ["warehouses::outgoing::inventoryAudit"],
            },
            {
              name: this.$t("menu.inventoryShortage"),
              image: "home-trend-down",
              url: "/outgoing/inventory-shortage",
              path: ["warehouses::outgoing::inventoryShortage"],
            },
          ],
        },
        {
          name: this.$t("menu.products"),
          image: "box",
          url: null,
          open: 1,
          path: ["products::products"],
          subMenus: [
            {
              name: this.$t("menu.draft"),
              image: "draft",
              url: "/products/draft",
              path: ["products::products"],
            },
            {
              name: this.$t("menu.active"),
              image: "active",
              url: "/products/active",
              path: ["products::products"],
            },
            {
              name: this.$t("menu.archive"),
              image: "archived",
              url: "/products/archived",
              path: ["products::products"],
            },
          ],
        },
        {
          name: this.$t("menu.materials"),
          image: "ruler",
          url: null,
          open: 1,
          path: ["products::materials"],
          subMenus: [
            {
              name: this.$t("menu.draft"),
              image: "draft",
              url: "/materials/draft",
              path: ["products::materials"],
            },
            {
              name: this.$t("menu.active"),
              image: "active",
              url: "/materials/active",
              path: ["products::materials"],
            },
            {
              name: this.$t("menu.archive"),
              image: "archived",
              url: "/materials/archived",
              path: ["products::materials"],
            },
          ],
        },
        {
          name: this.$t("menu.analytics"),
          image: "status-up",
          url: null,
          path: ["products::*"],
          subMenus: [
            {
              name: this.$t("menu.inventory"),
              image: "diagram",
              url: "/analytics/inventory",
              path: ["products::*"],
            },
            {
              name: this.$t("menu.web"),
              image: "web",
              url: "/analytics/web",
              path: ["products::*"],
            },
          ],
        },
        {
          name: this.$t("menu.production"),
          image: "scissor",
          url: null,
          path: ["production", "production::*"],
          subMenus: [
            {
              name: this.$t("menu.planner"),
              image: "task-list",
              url: "/production/designer",
              path: ["production::*"],
            },
            {
              name: this.$t("menu.designer"),
              image: "calendar",
              url: "/production/materials",
              path: ["production"],
            },
            {
              name: this.$t("menu.operations"),
              image: "chart-3",
              url: "/production/operations",
              path: ["production::operations"],
            },
          ],
        },
        {
          name: this.$t("menu.assets"),
          image: "assets",
          url: "/assets",
          path: ["assets"],
        },
        {
          name: this.$t("menu.crm"),
          image: "partners",
          url: null,
          path: ["crm::customers", "crm::partners", "crm::teams"],
          subMenus: [
            {
              name: this.$t("menu.partners"),
              image: "partner-add",
              url: "/crm/partners",
              path: ["crm::partners"],
            },
            {
              name: this.$t("menu.team"),
              image: "user-tick",
              url: "/crm/team-list",
              path: ["crm::teams"],
            },
            {
              name: this.$t("menu.customers"),
              image: "customers",
              url: "/crm/customers",
              path: ["crm::customers"],
            },
          ],
        },
        {
          name: this.$t("menu.finance"),
          image: "wallet-money",
          url: null,
          path: ["finance"],
          subMenus: [
            {
              name: this.$t("menu.finance"),
              image: "wallet-money",
              url: "/finance",
              path: ["finance"],
            },
            {
              name: this.$t("menu.payments"),
              image: "wallet-money",
              url: "/finance/payments",
              path: ["finance"],
            },
            {
              name: "Sales statistics",
              image: "wallet-money",
              url: "/finance/sales",
              path: ["finance"],
            },
          ],
        },
      ],
      key: 0,
    };
  },
  computed: {
    apps() {
      return this.store.state.apps;
    },
    me() {
      return this.store.state.user;
    },
    language() {
      return this.store.state.user?.language_code;
    },
    filteredMenus() {
      if (typeof this.menus !== "object") {
        return [];
      }
      if (typeof this.me?.role?.roles_permissions !== "object") {
        return [];
      }
      let menu = [];
      let enabledPaths = this.me.role?.roles_permissions.map(
        (e) => e.permission_id
      );
      if (enabledPaths.indexOf("*") > -1) {
        return this.menus;
      }
      for (const item of this.menus) {
        if (item === undefined || item.path === undefined) {
          continue;
        }
        let menuAdded = false;
        for (const path of item.path) {
          if (item.subMenus !== undefined && item.subMenus.length > 0) {
            let subMenus = [];
            for (const subItem of item.subMenus) {
              if (subItem === undefined || subItem.path === undefined) {
                continue;
              }
              for (const subPath of subItem.path) {
                if (
                  enabledPaths.indexOf(subPath) > -1 ||
                  enabledPaths.indexOf(subPath + "::*") > -1
                ) {
                  subMenus.push(subItem);
                  continue;
                }
                let parts = subPath.split("::");
                for (const part of parts) {
                  if (
                    enabledPaths.indexOf(part) > -1 ||
                    enabledPaths.indexOf(part + "::*") > -1
                  ) {
                    subMenus.push(subItem);
                    break;
                  }
                }
              }
            }
            if (subMenus.length > 0) {
              item.subMenus = subMenus;
            } else {
              item.subMenus = undefined;
            }
          }

          if (path === undefined) {
            continue;
          }

          if (
            enabledPaths.indexOf(path) > -1 ||
            enabledPaths.indexOf(path + "::*") > -1
          ) {
            if (!menuAdded) {
              menuAdded = true;
              menu.push(item);
            }
            continue;
          }

          let parts = path.split("::");
          for (const part of parts) {
            if (
              enabledPaths.indexOf(part) > -1 ||
              enabledPaths.indexOf(part + "::*") > -1
            ) {
              if (!menuAdded) {
                menuAdded = true;
                menu.push(item);
              }
              break;
            }
          }
        }
      }
      return menu;
    },
  },
  watch: {
    show() {
      if (this.show) {
        document.getElementById("body-pd").classList.add("body-pd");
      } else {
        document.getElementById("body-pd").classList.remove("body-pd");
      }
    },
    language() {
      const _this = this;
      setTimeout(() => _this.key++, 200);
    },
    apps: {
      deep: true,
      handler() {
        this.loadAppsMenu();
      },
    },
  },
  methods: {
    menuSelected() {
      if (!this.basicShow) {
        this.show = false;
      }
    },
    showNavbar() {
      this.basicShow = !this.basicShow;
      localStorage.setItem("leftSidebarShow", this.basicShow);
    },
    changeShow(e) {
      if (e) {
        this.show = e;
      } else {
        this.show = this.basicShow;
      }
    },
    loadAppsMenu() {
      const menuRefs = {};
      for (const key in this.menus) {
        menuRefs[this.menus[key].name] = this.menus[key];
      }

      for (const key in this.apps) {
        if (this.appMenuIds.indexOf(this.apps[key].id) > -1) {
          continue;
        }
        this.appMenuIds.push(this.apps[key].id);
        if (this.apps[key].menu !== undefined) {
          for (const compKey in this.apps[key].menu) {
            if (
              this.apps[key].menu[compKey].group === undefined ||
              this.apps[key].menu[compKey].group.indexOf(
                this.apps[key].menu[compKey]
              ) === -1
            ) {
              this.menus.push(this.apps[key].menu[compKey]);
            } else if (
              menuRefs[this.apps[key].menu[compKey].group].subMenus.indexOf(
                this.apps[key].menu[compKey]
              ) === -1
            ) {
              menuRefs[this.apps[key].menu[compKey].group].subMenus.push(
                this.apps[key].menu[compKey]
              );
            }
          }
        }
      }
    },
  },
  created() {
    if (localStorage.getItem("leftSidebarShow")) {
      this.show = localStorage.getItem("leftSidebarShow") === "true";
      this.basicShow = this.show;
      if (this.show) {
        document.getElementById("body-pd").classList.add("body-pd");
      }
    }

    this.loadAppsMenu();
  },
};
</script>
