<template>
  <section
    class="top-navigation sticky-top"
    :class="{ 'show-phone': showMenu }"
  >
    <div class="row custom-input">
      <div class="col-md-4">
        <label class="input-group mb-1">
          <span class="input-group-text me-3" id="basic-addon1">
            <BaseIcon name="search" />
          </span>
          <input
            type="text"
            ref="search"
            class="form-control"
            :placeholder="$t('global.search')"
            :aria-label="$t('global.search-alt')"
            v-model="q"
          />
        </label>
      </div>
      <div class="col-md-4 mb-1">
        <DatePicker
          v-model="date"
          :onlyInRange="1"
          :range="true"
          :twoCalendars="true"
        />
      </div>
      <div class="col-md-4">
        <v-select
          v-model="warehouse"
          :options="
            warehouses.filter((e) => enabledWarehouses.indexOf(e.id) > -1)
          "
          label="name"
          :aria-label="$t('global.filter-storage')"
          :placeholder="$t('global.storages')"
          :reduce="(o) => o.id"
        >
          <template v-slot:selected-option="option">
            <BaseIcon name="shop" v-if="option.shop_id > 0" />
            <BaseIcon name="scissor" v-else-if="option.type === 2" />
            <BaseIcon
              name="buildings"
              v-else-if="option.fulfilment_app_id > 0"
            />
            <BaseIcon name="box" v-else-if="option.type === 3" />
            <BaseIcon name="delivery-info" v-else />
            <span class="ms-3">
              {{ option.name }}
            </span>
          </template>
          <template v-slot:option="option">
            <BaseIcon name="shop" v-if="option.shop_id > 0" />
            <BaseIcon name="scissor" v-else-if="option.type === 2" />
            <BaseIcon
              name="buildings"
              v-else-if="option.fulfilment_app_id > 0"
            />
            <BaseIcon name="box" v-else-if="option.type === 3" />
            <BaseIcon name="delivery-info" v-else />
            <span class="ms-3"> {{ option.name }} </span>
          </template>
        </v-select>
      </div>
    </div>
  </section>
</template>

<script>
import { useStore } from "vuex";
import DatePicker from "./inputs/DatePicker";
import BaseIcon from "@/components/icons/BaseIcon";

export default {
  name: "TopBar",
  components: { BaseIcon, DatePicker },
  data() {
    let store = useStore();
    let date = new Date();
    date.setDate(new Date().getDate() - 365);

    return {
      store: store,
      date: [date, new Date()],
      warehouse: null,
      q: "",
    };
  },
  watch: {
    warehouse() {
      if (this.warehouseStore !== this.warehouse) {
        this.$store.commit("setTopBar", {
          key: "warehouse",
          value: this.warehouse,
        });
      }
    },
    warehouseStore() {
      this.warehouse = this.warehouseStore;
    },
    date() {
      this.$store.commit("setTopBar", { key: "date", value: this.date });
    },
    q() {
      if (this.q.length > 2) {
        this.$store.commit("setTopBar", { key: "q", value: this.q });
      } else {
        this.$store.commit("setTopBar", { key: "q", value: null });
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", (event) => {
      if (
        event.target.nodeName !== "INPUT" &&
        event.ctrlKey &&
        event.key === "f"
      ) {
        this.$refs.search.focus();
        event.preventDefault();
      }
    });
  },
  computed: {
    showMenu() {
      return this.store.state.showSearchMenu;
    },
    warehouses() {
      let warehouses = this.store.state.warehouses;
      return warehouses.sort((a, b) => {
        const companyDiff = a.company_id - b.company_id;
        if (companyDiff !== 0) {
          return companyDiff;
        } else {
          if (!a.shop_id && !a.fulfilment_app_id && a.type !== 2) {
            return -1;
          } else if (!b.shop_id && !b.fulfilment_app_id && b.type !== 2) {
            return 1;
          } else if (!a.shop_id && b.shop_id) {
            return 1;
          } else if (!b.shop_id && a.shop_id) {
            return -1;
          } else if (a.type !== 2 && b.type === 2) {
            return -1;
          } else if (b.type !== 2 && a.type === 2) {
            return 1;
          } else {
            return a.country_id - b.country_id;
          }
        }
      });
    },
    enabledWarehouses() {
      return this.store.state.enabledWarehouses;
    },
    warehouseStore() {
      return this.store.state.topBar.warehouse;
    },
  },
};
</script>
